import React, { useContext, useEffect, useState } from 'react';
import './Dialog.scss';
import {tennatId} from "../../utils/RealClient.service";
import axios from 'axios';
import {useTranslation} from "react-i18next";
import { CartContext } from '../../contexts/reducers';
import { LanguagesFullCodes } from '../../utils/Languages';
import { Dialog as HeadlessDialog, DialogPanel } from '@headlessui/react'
import { MdClose } from 'react-icons/md';
import { useTenant } from '../../contexts/TenantProvider';
import { FiMail } from 'react-icons/fi';

const Dialog = (props:{selectedOption: number, open: boolean, onClose: () => void}) => {
    const [dialogText, setDialogText] = useState<any>("")
    const {t} = useTranslation()
    const {tenant} = useTenant()
    const {state} = useContext(CartContext);
    const [selectedOption, setSelectedOption] = useState(0)
    const [isDataLoading, setIsDataLoading] = useState(false)
    const [dialogContent, setDialogContent] = useState<any>()
    const urls = {
        0: 'https://dev-storeapi.bookit.one/api/v1/shop/tenant/terms-of-use',
        1: 'https://dev-storeapi.bookit.one/api/v1/shop/tenant/privacy-policy',
    }
    const loadText = (url: string) => {
        setIsDataLoading(true)
        axios.get(`${url}?X-Language=${LanguagesFullCodes[state.language]}&X-Tenant-ID=${tennatId}`).then(res => res.data).then(data => {
            setDialogText(data)
            setIsDataLoading(false)
        })
    }
    useEffect(() => {
        setSelectedOption(props.selectedOption)
    },[props.selectedOption])

    useEffect(() => {
        if (selectedOption == 0 || selectedOption === 1) {
            loadText(urls[selectedOption])
        }
        else {
            setDialogText("")
            setDialogContent(getContactText())
        }
    }, [selectedOption, state.language])

    const getContactText = () => {
        return (
            <>
            <h2 className="font-semibold text-3xl">{t("Skontaktuj się z nami")}</h2>
            <div className="mt-8">{t("Masz pytanie lub problem? Odezwij się do nas.")}</div>
            <div className="font-semibold mt-8">{t("Zwroty, reklamacje, zmiany terminów, rezerwacje grupowe")}</div>
            <div className="border-b pb-4 mb-4 pt-2 text-secondary flex"><FiMail className="mt-1"/><a className="pl-2" href={"mailto:" + tenant.facilitySettings.email}>{tenant.facilitySettings.email}</a></div>
            <div className="font-semibold">{t("Problemy przy składaniu zamówienia")}</div>
            <div className="border-b pb-4 mb-4 pt-2 text-secondary flex"><FiMail className="mt-1"/><a className="pl-2" href="mailto:kontakt@bookit.one">kontakt@bookit.one</a></div>
            <div className="font-semibold">{t("Współpraca z BookitOne")}</div>
            <div className=" pb-4 mb-4 pt-2 text-secondary flex"><FiMail className="mt-1"/><a className="pl-2" href="mailto:sprzedaz@bookit.one">sprzedaz@bookit.one</a></div>
            </>
        )
    }
    return (
        <div className={"dialog-wrapper"}>
            <HeadlessDialog  open={props.open} onClose={props.onClose} style={{width: "100%"}} className="absolute top-0 left-0 bg-white z-100 max-h-full h-full overflow-y-scroll">
            <DialogPanel
            transition
            className="relative transform overflow-auto max-h-full  h-full rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 w-full sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
             <div
                id={"dismissButton"}
                className={"absolute top-2 right-2 md:top-2 lg:top-4 lg:right-6 md:right-4 p-2 hover:bg-grey rounded cursor-pointer z-20"}
                onClick={() => props.onClose()}
            >
                <MdClose size={25}/>
            </div>
            <div className="pb-24 pt-12 lg:grid lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4 max-w-screen-xl mx-auto text-gray-800">
                <aside className="border-r pl-4">

                    <div className="">
                        <div onClick={() => setSelectedOption(0)} className={"transition-colors	duration-200 cursor-pointer py-2 text-gray-700 px-2 my-4  border-l-2 " + (selectedOption === 0 ? " border-l-secondary" : "border-l-white")}>
                            {t("Regulamin rezerwacji")}
                            </div>
                    </div>
                    <div className="border-t">
                        <div onClick={() => setSelectedOption(1)} className={"transition-colors	duration-200 cursor-pointer py-2 text-gray-700 px-2 my-4  border-l-2 " + (selectedOption === 1 ? " border-l-secondary" : "border-l-white")}>
                            {t("Polityka prywatności")}
                        </div>
                    </div>
                    <div className="border-t">
                        <div onClick={() => setSelectedOption(2)} className={"transition-colors	duration-200 cursor-pointer py-2 text-gray-700 px-2 my-4  border-l-2 " + (selectedOption === 2 ? " border-l-secondary" : "border-l-white")}>
                            {t("Pomoc")}
                        </div>
                    </div>
                </aside>
                {dialogText !== "" ? 

                <div className={"ml-6 lg:col-span-2 xl:col-span-3"} dangerouslySetInnerHTML={{__html: dialogText}}/>
                : 
                <div className={"ml-8 lg:col-span-2 xl:col-span-3"} >{dialogContent}
                </div>}
                </div>
                </DialogPanel>
            </HeadlessDialog>
        </div>
    );
};

export default Dialog;
