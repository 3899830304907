import axios from 'axios'
import detectLanguage from './LanguageDetector';
import { LanguagesFullCodes } from './Languages';
import { md5 } from './Crypto';

const convert = require('color-convert');
export const baseURL = process.env.REACT_APP_API_BASEURL + '/api/v1/shop/'
export const tennatId = (new URLSearchParams(window.location.search)).get('tenantId') ?? getTenantIdFromHostname()

export const authHeader = (new URLSearchParams(window.location.search)).get('auth')
var tenantLanguage = LanguagesFullCodes[detectLanguage()]
//@ts-ignore
try { tenantLanguage = localStorage.getItem('lang') ??  LanguagesFullCodes[detectLanguage()]} catch(e) {tenantLanguage =  LanguagesFullCodes[detectLanguage()]}

export function getTenantIdFromHostname(): string {

        const TENANT_UUID_MAP = {
            "0d5798c588e019ddc826cc50af16b986": "00ae9d3d-fcab-4d0a-86bb-aa74caf60b66",
            "1c6c688f81bc6fc6ebd3533ad0dc91f9": "3a41041e-ec98-4bb2-8676-2a9a988ca5e6",
            "7c7d1c0dd2e34a13ef3dae2811508752": "de477438-a31d-46a6-817e-37fcf8c3a8f6",
            "7fb271ddccb8491b8b289e4de0dc17bd": "40bea4c2-27aa-45c2-9cc6-6a715df237cd",
            "9d693f70ec4d2f5db47f6bc615bb1eed": "c0110000-454f-4915-8bd5-1bcb26da61de",
            "240d27f2965eb4ed7d2749c5650be296": "96fbf968-be86-42dc-b826-de6f5e358ac5",
            "291d15ea0c0b70d56e73aa70d3078a22": "31ec411c-1f30-4b85-af62-462af4b7ca5a",
            "421aa90e079fa326b6494f812ad13e79": "00000000-0000-0000-0000-000000000000",
            "561e44f0383bfe679cdc0626c60cf48b": "1ec74d51-29d4-4fd1-a91f-0b284e74b5ff",
            "7951a569761fbfa4e69794904c24bc35": "1a5100ae-479f-4771-8a02-56e539d9c9f1",
            "682870b9f228811d5efe537fd834cf0f": "4516daf7-597e-4e8d-9455-3e45375cf8e0",
            "4842884c5920bce6bde311e1abd06c56": "6c751044-5717-4562-b3fc-2c963f66afa6",
            "a0e0a99dd8cb0930ffdf3a8c92e881f9": "7040da29-4b04-4877-85f3-5b37c30a1b68",
            "b61ba331be52003e7ade2409f6e4e15c": "00000000-0000-0000-0000-000000000000",
            "c52be434d45e50899e51f252cb3f8672": "405a4030-5151-4cff-a9c5-b9a866f6137d",
            "d40ac886ae4eb65dcacad08e1285d3b9": "00000000-0000-0000-0000-000000000001",
            "e95881b8e8d610a5a7c6b7580a370531": "6e57e13e-0f9a-4e15-9d0a-0e3de31a968b",
            "f8ca410c2d3b15d2519a39adaa1cbbdc": "ba571035-073d-4faa-b29e-9d85b840945c",
            "faa047b4f28eb025f6fb7fa9a75a4d1b": "f0473ca0-0992-4f5c-8a8f-b04ee4cdd0d7",
            "fb105a5c1d6fa463bd5578f8676445ea": "ba7a716e-d2f9-4066-9d0f-07aa0cf4fb22",
            "fdf6d61f824909ea21b14df673377e94": "112bd564-5717-4562-b3fc-2c963f66afa6",
        }
    const hostname  =  window.location.hostname
    if (Object.keys(TENANT_UUID_MAP).includes(md5(hostname))) {
        //@ts-ignore
        return TENANT_UUID_MAP[md5(hostname)]
    } 
    return '00000000-0000-0000-0000-000000000000'
}

function is_color_light(color: string) {
    const hex = color.replace('#', '');
    const c_r = parseInt(hex.substr(0, 2), 16);
    const c_g = parseInt(hex.substr(2, 2), 16);
    const c_b = parseInt(hex.substr(4, 2), 16);
    const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
    return brightness > 137;
}


export const color = ((new URLSearchParams(window.location.search)).get('color') ?? '7aa329')
const hsl = convert.hex.hsl(color);
const rgb = convert.hex.rgb(color)
document.documentElement.style.setProperty('--accent-color', '#' + color);
document.documentElement.style.setProperty('--accent-contrast-color',is_color_light(color) ? '#2c3034' : '#ffffff')
document.documentElement.style.setProperty('--accent-color-darker', 'hsl(' + hsl[0] + ', ' + hsl[1] + '%, ' + (hsl[2] - 10) + '%)');
document.documentElement.style.setProperty('--accent-color-darker2', 'hsl(' + hsl[0] + ', ' + hsl[1] + '%, ' + (hsl[2] - 2) + '%)');

document.documentElement.style.setProperty('--accent-color-dimmed', 'rgba(' + rgb[0] + ', ' + rgb[1] + ', ' + rgb[2] + ', 0.1)');
document.documentElement.style.setProperty('--accent-color-lighter', 'rgba(' + rgb[0] + ', ' + rgb[1] + ', ' + rgb[2] + ', 0.85)');

const instance = axios.create({
    baseURL: baseURL,
    timeout: 25000,
    headers: {
        'X-Tenant-ID': tennatId,
        'X-Language': tenantLanguage,
        ...(authHeader && { Authorization: authHeader })
    }
})
instance.interceptors.response.use(
    (response) => response,
    (error) =>
    {
        if (!window.navigator.onLine && (!error.response || error.response === undefined)) {
            //@ts-ignore
            window.location = "/offline"
            
        } else {
            return Promise.reject(
                (error.response && error.response.data) || 'Something went wrong!'
            )
        }
    }
)

export const client = instance

