import moment from 'moment'
import React, { useState } from 'react'
import { getDetails } from '../utils/Api.service'
import { Button } from '@headlessui/react'
import { FaBolt, FaCloud } from 'react-icons/fa'

const OfflinePage = () => {
    const [isTesting, setIsTesting] = useState(false)
    const testOffline = () => {
        setIsTesting(true)
        const date = moment().format('yyyy-MM-DD')
        getDetails().then(res => reload()).catch(exception => {
            setTimeout(() => {
            setIsTesting(false)}, 500)}
        )
    }

    const reload = () => {
        setIsTesting(false)
        window.location.replace('/')
    }
    return <div className="full-screen">
        <div className="text-center pt-1-3">
            <div style={{height:220}}>
                <FaBolt className="text-secondary absolute top-32 "  style={{marginLeft: 'calc(50% - 30px)', fontSize: 70, WebkitTextStrokeWidth: 3, WebkitTextStrokeColor: "#272b34", }}/>
                <FaCloud className="text-grey-100  absolute ml-5/10 top-0" style={{marginLeft: 'calc(50% - 70px)', fontSize: 150, WebkitTextStrokeWidth: 3, WebkitTextStrokeColor: "#eeeeee", }}/>

            </div>
            <div className="container  mx-auto">
                <div className="font-light mt-4 text-2xl font-bold">Uups</div>
                <h5 className="text-light-white font-light mt-4 mx-10">Nie znaleziono połączenia z internetem. Sprawdź swoje połączenie i spróbuj ponownie.</h5>
                <div className="mt-8">
                    <Button className="text-white border bg-secondary border-secondary px-24 py-2 uppercase" onClick={testOffline}>Odśwież</Button>
                </div>
            </div>
        </div></div>
}

export default OfflinePage