import React, {useContext, useEffect, useState} from 'react';
import {RiCoupon2Line} from "react-icons/ri";
import {updateCart} from "../../utils/Api.service";
import {CartError} from "../../models/Cart.model";
import {CartContext, Types} from "../../contexts/reducers";
import ReactGA from "react-ga4";
import {useTranslation} from "react-i18next";

type VoucherSelectorProps = {
    voucherValidity: number,
    voucherCodeInputVisible: boolean
    isIconHidden?: boolean,
    onHideVoucherSelector?: () => void,
    title: string,
    description: string
}

const VoucherSelector = (props: VoucherSelectorProps) => {
    const {t} = useTranslation()

    const {state, dispatch} = useContext(CartContext);
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [voucherCodeVisible, setVoucherCodeVisible] = useState<boolean>(false);
    const [voucherCode, setVoucherCode] = useState<string>('')
    const [voucherSubmitButtonDisabled, setVoucherSubmitButtonDisabled] = useState<boolean>(false);

    const validateVoucherCode = (code: string) => {
        const newCart = {
            ...state.cart,
            discounts: [{code: code}]
        }
        updateCart(newCart).then((result) => {
            dispatch({type: Types.UpdateCart, payload: result})
            const appliedDiscount = result.discounts?.find(x => x.code === code)
            if (appliedDiscount) {
                if (props.onHideVoucherSelector) {
                    props.onHideVoucherSelector()
                }
                ReactGA._gtag("event", "selected_promotion", {
                    creative_name: appliedDiscount.type,
                    promotion_id: appliedDiscount.code,
                    promotion_name: appliedDiscount.name,
                })
            }
        }).catch((errorResult: CartError) => setErrorMessage(errorResult.errors.discounts.find(x => x.key === voucherCode)?.message ?? ''))
    }

    useEffect(() => {
        setVoucherCodeVisible(props.voucherCodeInputVisible)
    })
    return (
        <React.Fragment>
            {props.isIconHidden !== true &&
            <div className={"icons"}>
                <RiCoupon2Line size={50} className={"mobile-icon"}/>
                <RiCoupon2Line size={60} className={"desktop-icon"}/>
            </div>}
            {!voucherCodeVisible && <div className={"title"}>{t(props.title)}</div>}
            {!voucherCodeVisible && <div className="description" dangerouslySetInnerHTML={{__html: t(props.description)}}/>}
            {
                <div className={"p-fluid p-formgrid absolute buttom-0 left-0 mx-auto transition ease-in-out delay-200  w-full opacity-0 "  + (voucherCodeVisible ? "opacity-100":"")}>
                    <div className="p-field mx-4 sm:mx-2 ">
                        <div className="">
                            {
                                errorMessage ? <span className={"text-red"}> {t(errorMessage)} </span> :
                                    <div className={"voucher-title"}>{t('Podaj kod z vouchera')}</div>
                            }
                            <input className={"text-center w-full m-0 p-2 border-grey border hover:border-secondary rounded active:border-secondary focus:outline-none"} onChange={e => {setVoucherCode(e.target.value)}}/>
                        </div>
                        <button className="p-2 mt-2 bg-secondary text-white border-2 border-secondary hover:bg-white hover:text-secondary rounded
                     w-72 flex justify-center text ext-grey-400font-bold mx-auto mb-2 max-w-full"
                                onClick={() =>validateVoucherCode(voucherCode)}
                                disabled={voucherSubmitButtonDisabled}
                        >{t('Zrealizuj')}</button>
                    </div>
                </div>
            }
        </React.Fragment>
    );
}
export default VoucherSelector
