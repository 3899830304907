import React, { useState } from 'react';
import './Footer.scss';
import Dialog from './Dialog';
import {useTranslation} from "react-i18next";

const Footer = () => {
    const currentYear = new Date().getFullYear();
    const {t} = useTranslation()
    const [isDialgOpened, setIsDialogOpened] = useState(false)
    const [selectedOption, setSelectedOption] = useState(0)
    return (
        <footer className=" mt-24 sm:mt-16 mb-12 md:mb-4" >
            <Dialog open={isDialgOpened} onClose={() => setIsDialogOpened(false)} selectedOption={selectedOption} />
            <ul className="text-grey-700">
                <li onClick={() => {setSelectedOption(0); setIsDialogOpened(true)}}>{t('Regulamin rezerwacji')}</li>
                <li onClick={() => {setSelectedOption(1); setIsDialogOpened(true)}}>{t('Polityka prywatności')}</li>
                <li onClick={() => {setSelectedOption(2); setIsDialogOpened(true)}}>{t('Pomoc')}</li>
            </ul>
            <div className={"copyright text-grey-700"}>&copy; {currentYear} Bookit.one</div>
        </footer>
    );
};

export default Footer;
