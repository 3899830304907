import React, { useContext, useEffect, useState } from "react"
import {MdClose} from "react-icons/md";
import {useTranslation} from "react-i18next";
import { BiCheckCircle, BiError } from "react-icons/bi";
import { FiClock } from "react-icons/fi";
import moment from "moment";
import { getFormattedPrice } from "../utils/PriceUtils";
import { TenantInfo } from "../models/Details.model";
import { getDetails, getOrderDetals } from "../utils/Api.service";
import { CurrencyContext } from "../contexts/CurrencyProvider";
import { getCartItemLabel } from "../utils/UnitUtils";
import { CartContext } from "../contexts/reducers";
import { usePostHog } from "posthog-js/react";
import { OrderSummary } from "../models/Order.model";
import LanguageSelector from "../components/LanguageSelector/LanguageSelector";
import { FaFacebook, FaFacebookF, FaInstagram } from "react-icons/fa";
import ManageReservationDialog from "../components/ManageReservationDialog/ManageReservationDialog";
import { useNavigate } from "react-router-dom";

const ThankYouPage = (props: any) => {
    const navigate = useNavigate();
    const [status, setStatus] = useState<string>('PENDING')
    const [isManageBookingOpened, setIsManageBookingOpened] = useState<boolean>(false);
    const [orderType, setOrderType] = useState<string>('PACKAGE_RESERVATION')
    const posthog = usePostHog()
    const {t} = useTranslation()
    const { state } = useContext(CartContext);

    const [tenantInfo, setTenantInfo] = useState<TenantInfo>();
    const {changeCurrency, currency} = useContext(CurrencyContext);

    const [order, setOrder] = useState<OrderSummary>()
    const [booking, setBooking] =useState<{date: string, time: string}|null>(null)
    
    const dismissWindows = () => {
        // eslint-disable-next-line
        parent.postMessage({type: "bookitone-dismiss"}, '*')
    }

    const getOrderStatus = (order: any): string => {
        if (order.orderStatus === 'CANCELLED' || order.orderStatus === 'FAILED') {
            return 'CANCELLED'
        }
        if (order.orderStatus == 'PLACED') {
            if (order.payment === null) {
                if (order.finalPrice === 0) {
                    return 'CONFIRMED';
                } else {
                    return 'CANCELLED';
                }
            }
            if (order.payment.status === 'PENDING') {
                if (order.payment.paymentMethod === 'CASH') {
                    return 'ONSITE';
                }
                return 'PENDING';
            }
            
            if (order.payment.status === 'FAILED') {
                return 'CANCELLED'
            }
            if (order.payment.status == 'CONFIRMED') {
                return 'CONFIRMED';
            }
        }
        if (order.orderStatus === 'PROCESSING') {
            if (order.payment.paymentMethod === 'CASH') {
                return 'ONSITE';
            }
            return 'PENDING';
        }
        return "CONFIRMED";
        
    } 

    const assignBooking = (order:OrderSummary): any => {
        if (order.reservationType === "IMMEDIATE") {
            setOrderType("IMMEDIATE");
            setBooking(null);
        } else if ( order.reservationType === "VOUCHER") {
            setOrderType("VOUCHER");
            setBooking(null);
        } else if (order.reservationType === "TIMESLOT") {
            setOrderType("PACKAGE_RESERVATION");
            const date = moment(order.booking.dateTime)
            setBooking({
                date: date.format("dddd, D MMM yyyy"),
                time: date.format('HH:mm')
            })
        } else if (order.reservationType === 'NONE') {
            //@ts-ignore
            if (order.items.find(x => x.timeslot !== null)) {
                setOrderType("RANGE_RESERVATION");
                const bookingPayload = order.items.find(x => x.timeslot !== null)?.timeslot
                if (bookingPayload) {
                    setBooking({date: moment(new Date(bookingPayload.date + "T" +bookingPayload.time + ":00")).format("dddd, D MMM yyyy"), time: bookingPayload?.time })
                }
            } else {
                setOrderType("IMMEDIATE");
                setBooking(null);
            }
        }
    }
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const tennatId = urlParams.get('tenantId') ?? '00000000-0000-0000-0000-000000000000'
        posthog.group('tenant', tennatId)

        const orderId = urlParams.get('orderId') ?? ''
        const order = {
            id: '123',
            tenantOrderId: '1233',
            creationTimestamp: '2024-09-22T16:00:00',
            finalPrice: 12900,
            basePrice: 12900,
            duration: 30,
            orderStatus: 'CONFIRMED',
            reservationType: 'TIMESLOT',
            items: [{
                sku: '123', 
                quantity: 1,
                name: 'Pakiet startowy',
                quantityMultiplier: 1, 
                unit: "PIECE",
                price: 12900,
                priceAfterDiscount: 12900,
                children: [],
                type:'BUNDLE',
                timeslot: null,
                upsell: [],
            }],
            discounts: [],
            payment: {status: 'CONFIRMED', paymentMethod: 'payu'},
            booking: {
                status: 'CONFIRMED',
                dateTime: '2024-09-22T16:00:00',
                duration:30
            },
            currency: 'PLN'
        }
        assignBooking(order)
        setStatus(getOrderStatus(order))
        setOrder(order)

        getOrderDetals(orderId).then(order => {
            assignBooking(order)
            setStatus(getOrderStatus(order))
            setOrder(order) 

        })

        getDetails().then(tenantInfo => {
            setTenantInfo(tenantInfo)
           
            changeCurrency(tenantInfo.defaultCurrency)

        })
    }, [])

    const isManageReservationActive = (): boolean => {
        return false
    }
    const canRefundOrder = (): boolean => {
        return true
    }

    const canRescheduleOrder = (): boolean => {
        return true;
    }



    return (
        <React.Fragment>
            <div className={"mx-auto w-full lg:w-600px mt-0 lg:my-4 bg-white  rounded lg:border border-zinc-200 relative flex flex-col" }>
           
                { <div className={"absolute top-2 right-2 md:right-4  lg:top-4 lg:right-6 p-2 text-white hover:text-zinc-700 hover:bg-zinc-200 rounded cursor-pointer z-10"} onClick={dismissWindows}>
                    <MdClose size={25}/>
                </div>}
                <div className='text-white hover:text-zinc-700'>
                <LanguageSelector supportedLanguages={tenantInfo?.supportedLanguages ?? []}/>
                </div>
                <div className="hero h-300px flex flex-col-reverse">
                    <div 
                    className="w-full h-300px top-0 p-0 m-0 absolute  bg-cover bg-center bg-zinc-600" 
                        style={{
                            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('${tenantInfo?.facilitySettings.backgroundPhotoUrl}')`
                            }}>
                    </div>
                   
                    <h1 className="z-10 font-black text-white text-4xl pl-6 pb-4">{tenantInfo?.tenantName}</h1>
                    <div className={"  right-2 md:right-4 text-white rounded cursor-pointer z-10 flex pl-6 pb-2"}>
                        {tenantInfo?.facilitySettings.socialMedia.facebook && 
                            <a href={tenantInfo?.facilitySettings.socialMedia.facebook} target="_blank">
                            <div className=" p-2 border-2 border-zinc-200 hover:border-transparent hover:bg-zinc-200 hover:text-primary transition-all rounded	mr-2">
                                <FaFacebookF size={21}/>
                            </div>
                            </a>
                        }
                        {tenantInfo?.facilitySettings.socialMedia.instagram && 
                            <a href={tenantInfo?.facilitySettings.socialMedia.instagram} target="_blank">
                            <div className=" p-2 border-2 border-zinc-200 hover:border-transparent hover:bg-zinc-200 hover:text-primary transition-all rounded	">
                                <FaInstagram size={21}/>
                            </div>
                            </a>
                        }
                    </div>
                </div>
                {order && <>
                    <div className="mt-8 mb-4 mx-6">
                        {status === 'CONFIRMED' && 
                        <span className="bg-green rounded-full px-4 py-2 font-lg text-white"><BiCheckCircle size={16} className="inline mr-2 mb-1"/>{t("Potwierdzono")}</span>}
                        {status === 'ONSITE' && 
                        <span className="bg-green rounded-full px-4 py-2 font-lg text-white"><BiCheckCircle size={16} className="inline mr-2 mb-1"/>{t("Potwierdzono, zapłać na miejscu")}</span>}
                        {status === 'PENDING' && 
                        <span className="bg-orange-400 rounded-full px-4 py-2 font-lg text-white"><FiClock size={16} className="inline mr-2 mb-1 "/>{t("Oczekiwanie na płatność")}</span>}
                        {status === 'CANCELLED' && 
                        <span className="bg-rose-600 rounded-full px-4 py-2 font-lg text-white"><BiError size={16} className="inline mr-2 mb-1 "/>{t("Anulowano")}</span>}

                    </div>
                    {booking && 
                    <div className="mt-4 mx-6">
                        <span className="font-semibold text-primary text-3xl pb-4">{booking.date} {t("o")} {booking.time}</span>
                    </div>}
                    {orderType === 'VOUCHER' && 
                    <div className="mt-4 mx-6">
                        <span className="font-semibold text-primary text-3xl pb-4">{t("Voucher prezentowy")}</span>
                       
                    </div>}
                    {order.duration > 0 && 
                    <div className="mt-1 mx-6">
                        <span className="text-zinc-500 font-light">{t("Czas trwania")}: {order.duration}min</span>
                    </div>}
                    <div className="mt-1 mx-6">
                        <span className="text-zinc-500">{t("Nr zamówienia")}:</span><span className="text-zinc-500 pl-1">{order.tenantOrderId}</span>
                    </div>
                    {order.payment && 
                    <div className="mt-1 mx-6">
                        <span className="text-zinc-500">{t("Metoda płatności")}:</span><span className="text-zinc-500 pl-1">
                            {order.payment.paymentMethod === 'CASH' || order.payment.paymentMethod === 'CARD'  ? t('Na miejscu') : ''}
                            {order.payment.paymentMethod === 'ONLINE' || order.payment.paymentMethod === 'PAYU'  || order.payment.paymentMethod === 'PAYNOW' ? t('Online') : ''}
                            </span>
                    </div>}
                    {order.discounts.length >0 && order.discounts[0].type === 'BUNDLE_VOUCHER' &&
                    <div className="mt-1 ml-6">
                    <span className="text-zinc-500">{t("Metoda płatności")}:</span><span className="text-zinc-500 pl-1">
                        {t("Voucher prezentowy")}
                        </span>
                    </div>}
                    {orderType === 'VOUCHER' && <div className="mt-1 mx-6">
                            <span className="pt-4 font-bold text-zinc-700  pb-4">{t("Po opłaceniu zamówienia voucher zostanie wysłany na adres email podany w zamówieniu")}.</span>
                        </div>}
                    <div className="mt-4 ml-6">
                        <span className="text-zinc-700 font-bold text-xl">{t("Podsumowanie")}</span>
                    </div>
                    <div>
                        <div className="my-4 mx-6">
                            { //@ts-ignore
                            order && order.items.filter(x => x.type !== 'CONFIGURABLE' && x.type !== 'VARIANT' && x.type !== 'MONEY_VOUCHER').map((kit, index) => {
                                return (<div key={index}>
                                    <div className="flex mt-2">
                                        <span className="w-2/3 text-left text-zinc-700">
                                            {kit.quantity > 1 && kit.quantity + " " +t("szt.")} {kit.name}
                                        </span>
                                        <span className="w-1/3 text-right text-zinc-700">
                                            {getFormattedPrice(kit.price, true, currency)}
                                        </span>
                                    </div>
                                    {(kit.upsell?.length ?? 0) > 0 &&
                                    <div className="pl-6">
                                        { //@ts-ignore
                                        kit.upsell?.map((el, index) => {
                                            return (
                                                <div className="w-full flex" key={index}>
                                                    <span className="w-3/4 text-left text-zinc-700">
                                                        + {el.name} ({getCartItemLabel(el, state.language).map((result, index) => (result))})
                                                    </span>
                                                    <span className="w-1/4 text-right text-zinc-700">
                                                     {getFormattedPrice(el.price * el.quantity, true, currency)}
                                                    </span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    }
                                </div>)
                            })}

                            { //@ts-ignore
                            order &&  order.items.filter(x => x.type === 'CONFIGURABLE' || x.type === 'VARIANT' || x.type === 'MONEY_VOUCHER').map((kit, index) => {
                                return (<div key={index}>
                                    <div className="flex mt-2">
                                        <span className="w-2/3 text-left text-zinc-700">
                                            {kit.quantity} x {kit.name}
                                        </span>
                                        <span className="w-1/3 text-right text-zinc-700">
                                            {kit.quantity > 1 && kit.quantity + "x" } {getFormattedPrice(kit.price, true, currency)}
                                        </span>
                                    </div>
                                    {(kit.upsell?.length ?? 0) > 0 &&
                                    <div className="pl-6 pr-2">
                                        { //@ts-ignore
                                        kit.upsell?.map((el, index) => {
                                            return (
                                                <div className="w-full flex" key={index}>
                                                    <span className="w-3/4 text-left text-zinc-700">
                                                        + {getCartItemLabel(el, state.language).map((result, index) => (result))} {el.name} 
                                                    </span>
                                                    <span className="w-1/4 text-right text-zinc-700">
                                                        + {getFormattedPrice(el.price * el.quantity, true, currency)}
                                                    </span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    }
                                </div>)
                            })}
                             {order.discounts.length >0 && order.discounts[0].type === 'BUNDLE_VOUCHER' && order.discounts.filter(x => x.type === 'BUNDLE_VOUCHER').map((voucher, discountIndex) => {
                                return voucher.items?.map((kit, index) => {
                                    return (<div className={""} key={`${discountIndex}${index}`}>
                                        <div className="flex px-2 mt-2">
                                            <span className="w-2/3 text-left text-zinc-700"> {kit.quantity > 1 && kit.quantity + " x "} {kit.name}</span>
                                            <span className="w-1/3 text-right text-zinc-700"></span>
                                        </div>
                                        {(kit.upsell?.length ?? 0) > 0 &&
                                        <div className="pl-6 pr-2">
                                            {kit.upsell?.map((el, index) => {
                                                return (
                                                    <div className="w-full flex" key={index}>
                                                        <span className="w-3/4 text-left text-zinc-700">
                                                        + {getCartItemLabel(el, state.language).map((result, index) => (result))} {el.name} 
                                                        </span>
                                                        <span className="w-1/4 text-right text-zinc-700">
                                                        </span>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        }
                                    </div>)
                                })
                            })}
                            <div className={"mt-4"}/>
                            {(order && order.discounts.length >0 && (order.discounts[0]?.value ?? 0) > 0) &&
                            <div>
                                <div className="flex ">
                                    <div className="text-lg font-bold text-left text-green w-full">
                                        {t('Rabat')}: { order.discounts[0]?.type === 'PERCENT_COUPON' ?  order.discounts[0]?.value + " %" : getFormattedPrice( order.discounts[0]?.value ?? 0, true, currency)}
                                    </div>
                                    <div className=" text-lg font-bold text-right text-green w-full">
                                        - {getFormattedPrice(order.basePrice -order.finalPrice, true, currency)}
                                    </div>
                                </div>
                            </div>}
                            <div className="flex text-zinc-700">
                                <div className="text-lg font-bold text-left w-1/2">{t('Suma')}:</div>
                                <div className="text-lg font-bold text-right w-1/2">{getFormattedPrice(order.finalPrice, true, currency)}</div>
                            </div>
                        </div>
                    </div>
                </>}
                <div className="mt-6 ml-6">
                        <span className="text-zinc-700 font-bold text-xl">{t("Dodatkowe informacje")}</span>
                    </div>
                <div className="mt-2 ml-6 ">
                    <span className="text-zinc-700" dangerouslySetInnerHTML={{__html: tenantInfo?.reservationTerms ?? ""}}></span>
                </div>

                <div className="mt-6 ml-6">
                        <span className="text-zinc-700 font-bold text-xl">{t("Adres")}</span>
                    </div>
                <div className="mt-2 ml-6 mb-8">
                    <span className="text-zinc-700">
                        {tenantInfo?.facilitySettings.address}<br/>
                        {tenantInfo?.facilitySettings.zipCode} {tenantInfo?.facilitySettings.city}
                    </span>
                </div>
                {isManageReservationActive() && 
                <div id="manage-reservation" className="mt-2 mx-6">
                    <button onClick={() => navigate('/order/manage'+window.location.search)} className="hover:bg-secondary-dimmed w-full py-3 text-lg font-medium text-center border border-secondary rounded text-secondary">{t("Zarządzaj rezerwacją")}</button>
                    {/* <button className="hover:bg-red hover:text-white text-red my-4 w-full py-3 text-lg font-medium text-center border-2 border-red bg-red-dimmed rounded-full">{t("Anuluj zamówienie")}</button> */}

                </div>}
                <ManageReservationDialog orderId="123" tenantOrderId="123" currentBooking={{datetime: "2024-09-22T16:00:00", duration: 30}} wasOrderFulfilled={false} wasOrderRescheduled={false} isOrderCancelled={false} isOrderInNext24h={false} isDialogOpened={isManageBookingOpened} onDialogClose={() => setIsManageBookingOpened(false)}/>
                <div className="mt-2 text-center mb-6">
                    <div className="inline-block mx-auto"><a href="https://bookit.one" target="_blank" rel="norel" >Powered by<img src="https://bookit.one/images/logo.png" width={100} className="inline pl-4"/></a></div>
                </div>
            </div>
            
        </React.Fragment>
    )
}
export default ThankYouPage