import React, {useContext, useEffect, useState, useRef} from 'react';
import Confirmation from "../components/Confirmation/Confirmation";
import CalendarSelector from "../components/CalendarSelector/CalendarSelector";
import Packages from "../components/Packages/Packages";
import {getCalendar, getDetails, getItems, getTags, updateCart} from "../utils/Api.service";
import {CalendarDay} from "../models/CalendarDay.model";
import Cart from "../components/Cart/Cart";
import './PurchasePage.scss'
import PurchaseNavigation, {MenuItem} from "../components/PurchaseNavigation/PurchaseNavigation";
import classNames from "classnames";
import OrderTypeSelector from "../components/OrderTypeSelector/OrderTypeSelector";
import {SellableProduct} from "../models/Product.model";
import {MdClose} from "react-icons/md";
import ReactGA from 'react-ga4';
import {ConfirmPopup} from 'primereact/confirmpopup';
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import { BackButton } from "../components/Ranges/BackButton";

import Footer from "../components/Footer/Footer";
import {CartContext, Types} from "../contexts/reducers";
import {Tag} from "../models/Tags.model";
import VoucherSelector from "../components/OrderTypeSelector/VoucherSelector";
import { CartError } from '../models/Cart.model';
import LanguageSelector from "../components/LanguageSelector/LanguageSelector";
import {useTranslation} from "react-i18next";
import { useParams } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen/LoadingScreen';
import { CurrencyContext } from '../contexts/CurrencyProvider';
import { usePostHog } from 'posthog-js/react';
import ReactPixel from 'react-facebook-pixel';
import { useCookiesConsent } from '../contexts/CookiesProvider';
import { useFeatureValue,useGrowthBook } from '@growthbook/growthbook-react';
import { useTenant } from '../contexts/TenantProvider';
import CartRuleNotModelModal from '../components/CartRuleNotMetModal/CartRuleNotMetModal';

const PurchasePage = (props: any) => {
    const {state, dispatch, getTotalCount} = useContext(CartContext);
    const {changeCurrency} = useContext(CurrencyContext);
    const posthog = usePostHog()
    const gb = useGrowthBook();

    const tenantReservationDelay = useFeatureValue('shop-packages-reservation-delay', '')
    const minPackages = useFeatureValue('shop-cart-min-packages', 1)
    const {tenant, setTenant, setTenantId} = useTenant()
    const { t } = useTranslation();
    const { orderType, sku } = useParams();
    const [cartRuleNotMetModalVisible, setCartRuleNotMetModalVisible] = useState<boolean>(false);

    const [dismissVisible, setDismissVisible] = useState<boolean>(false);
    const [products, setProducts] = useState<SellableProduct[]>([])
    const [allProducts, setAllProducts] = useState<SellableProduct[]>([])
    const [isProductsLoding, setIsProductsLoading] = useState<boolean>(true);
    const [activeIndex, setActiveIndex] = useState(-1)
    const [calendarDays, setCalendarDays] = useState<CalendarDay[]>()
    const [dismissAndCartVisible, setdismissAndCartVisible] = useState<boolean>(true)
    const [closeButtonVisible, setCloseButtonVisible] = useState<boolean>(false)
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [showDurationUpdatedPopup, setShowDurationUpdatedPopup] = useState<boolean>(false);
    const [loadingScreenMessage, setLoadingScreenMessage] = useState<string>("");
    const [tags, setTags] = useState<Tag[]>([]);
    const [availableTags, setAvailableTags] = useState<Tag[]>([]);
    const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
    const [loadingScreenLink, setLoadingScreenLink] = useState<{ link: string, text: string }>({link: "", text: ""});

    const [prevDuration, setPrevDuration] = useState<number>(0);

    const [isVoucherInputDialogOpened, setIsVoucherInputDialogOpened] = useState<boolean>(false);
    const [isVoucherDialogOpened, setIsVoucherDialogOpened] = useState<boolean>(false);
    const [isVoucherErrorDialogOpened, setIsVoucherErrorDialogOpened] = useState<boolean>(false);

    const [voucherMsg, setVoucherMsg] = useState<string>('');
    const [vouchersCounter, setVouchersCounter] = useState<number>(0);
    const selectedOption = useRef<null | HTMLDivElement>(null)
    const [skuToOpen, setSkuToOpen] = useState<string>('')
    const scrollableRef = useRef(null)

    const [menuItems, setMenuItems] = useState<MenuItem[]>([
        {label: t('Oferta'), active: true},
        {label: t(state.booking?.type !== 'voucher' ? 'Kalendarz' : 'Voucher'), active: false},
        {label: t('Podsumowanie'), active: false}
    ]);
    useEffect(() => {
        const prevItems = menuItems
        prevItems[1].label = state.booking?.type !== 'voucher' ? 'Kalendarz' : 'Voucher'
        setMenuItems(prevItems)
    }, [state.booking?.type])

    useEffect(() => {

        const newMenuItems = [...menuItems]
        newMenuItems[1].active = (getTotalCount() > 0)
        setMenuItems(newMenuItems)
        
    }, [state.cart])

    useEffect(() => {
        const newMenuItems = [...menuItems]
        newMenuItems[2].active = (state.booking !== undefined && state.booking !== null &&  (state.booking.type == 'timeslot' || state.booking.type === 'voucher' && Boolean(state.booking.owner)))

        setMenuItems(newMenuItems)
    }, [state.booking])
    const {statistics} = useCookiesConsent()

    useEffect(() => {
        if (statistics && tenant) {
            if (tenant.facebookPixelId) {
                ReactPixel.init(tenant.facebookPixelId, undefined, {autoConfig: true, debug: false})
                ReactPixel.pageView();
            }
            ReactGA.initialize([
                {trackingId: tenant.googleAnalyticsId, gaOptions: {cookieFlags: 'secure;samesite=none'}}])
            ReactGA.send({hitType:"pageview", page: '/order-type'})
        }
       
    }, [statistics, tenant])

    const validateVoucherCode = (code: string) => {
        const newCart = {
            ...state.cart,
            discounts: [{code: code}]
        }
        updateCart(newCart).then((result) => {
            dispatch({type: Types.UpdateCart, payload: result})
            const appliedDiscount = result.discounts?.find(x => x.code === code)
            if (appliedDiscount) {
                if (props.onHideVoucherSelector) {
                    props.onHideVoucherSelector()
                }
                ReactGA._gtag("event", "selected_promotion", {
                    creative_name: appliedDiscount.type,
                    promotion_id: appliedDiscount.code,
                    promotion_name: appliedDiscount.name,
                })
            }
        }).catch((errorResult: CartError) => setIsVoucherErrorDialogOpened(true))
    }
    const handleBackEvent = (event: any) => {
        if (event.data && event.data.type && event.data.type === 'updateIndex' ) {
            back()
        }
    }
    useEffect(() => {
        window.addEventListener("message",handleBackEvent,false);
        return () => window.removeEventListener('message', handleBackEvent)
    },[activeIndex])
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const tenantId = setTenantId()

        posthog.group('tenant', tenantId)            

        getDetails().then(tenantInfo => {
            setTenant(tenantInfo)
            changeCurrency(tenantInfo.defaultCurrency)

        })
        try {
            setCloseButtonVisible(window.self !== window.top)
        } catch (e) {
            setCloseButtonVisible(true)
        }
        getTags().then(tags => setAvailableTags(tags))
        const inputVoucher = urlParams.get('open-input-voucher')
        const voucher = urlParams.get('voucher')

        if (inputVoucher) {
          
            setIsVoucherInputDialogOpened(true)
        }
       
        if (voucher){
            validateVoucherCode(voucher)
            posthog.group('visitor','voucher')
        } else if (sku) {
            posthog.group('visitor','direct_link')
        } else {
            posthog.group('visitor','website')
        }
        posthog.capture('merch session_started packages')
        getItems(5000).then((res) => {
            if (sku && res.find(x => x.sku === sku)) {
                setSkuToOpen(sku)
            }
            setAllProducts(res)
            setProducts(res)
            setIsProductsLoading(false)
            window.scrollTo(0, 0)
            
        }).catch((e) => {
            getItems(undefined).then((res) => {
                setAllProducts(res)
                setProducts(res)
                setIsProductsLoading(false)
            })
        })

        
    }, [])
    useEffect(() => {
        if (availableTags.length > 0 && allProducts.length > 0) {
            const productTags = new Array<Tag>()
            allProducts.forEach((product) => {
                product.tags?.forEach((tag) => productTags.push(tag))
            })
            const uniqueProductTags = productTags.filter((v, i, a) => a.findIndex(el => el.key === v.key) === i)
            const showTags = availableTags.filter( x => uniqueProductTags.find( upt => upt.key === x.key))
            if (showTags.length === 0) {
                setSelectedTags([])
            }
            setTags(showTags)
            if (sku) {
            }
            
            if (orderType == 'voucher' || orderType == 'booking') {
                handleChangedType(orderType)
            } else if (orderType === 'order' && sku) {
                handleChangedType('')
            } else if (orderType === 'order') {
                return
            }
            else if (orderType) {
                handleChangedType(orderType,0)
            }
           
            
        }
    }, [availableTags, allProducts])
    useEffect(() => {
        if (selectedTags.length === 0) {
            setProducts(allProducts.filter(x => !['CONFIGURABLE_SHOOTING_RANGE'].includes(x.type)))
        } else {
            setProducts(allProducts.filter(x => x.type === 'SIMPLE' || x.type === 'EXTENSION' || (x.tags && x.tags.reduce<number>((acc, tag) => (acc +  selectedTags.filter(st => st.key === tag.key).length), 0) > 0)))
        }
        selectedOption.current?.scrollIntoView({ behavior: 'smooth',
        block: 'center',
        inline: 'center' });
    }, [selectedTags, allProducts])

    useEffect(() => {
        setTimeout(function () {
            selectedOption.current?.scrollIntoView({ behavior: 'smooth',
            block: 'center',
            inline: 'center' });
        }, 500)
       
    }, [tags])



    useEffect(() => {
        if (!gb.ready){
            return
        }
        const isMaster = (new URLSearchParams(window.location.search)).get('auth')
        const tommorow = new Date();
        if (!isMaster && tenantReservationDelay === 'day') {
            tommorow.setDate(tommorow.getDate() + 1)
            tommorow.setHours(0, 0, 0, 0)
        } else if (tenantReservationDelay === 'hour') {
            if (tommorow.getHours() >= 22) {
                tommorow.setDate(tommorow.getDate() + 1)
                tommorow.setHours(0,0,0,0)
            } else {
                tommorow.setHours(tommorow.getHours() + 1, 0,0,0)
            }
        }
        const next = new Date()

        next.setHours(0, 0, 0, 0)
        
        if (isMaster) {
            next.setTime(tommorow.getTime() + 124 * 24 * 60 * 60 * 1000)
        } else {
            next.setTime(tommorow.getTime() + 91 * 24 * 60 * 60 * 1000)
        }
        if (state.cart.duration > 0) {
            setPrevDuration(state.cart.duration)

                getCalendar(state.cart.duration, tommorow.toISOString(), next.toISOString(), state.cart.id).then(days => {
                    setCalendarDays(days)
                    let selectedDate = ''
                    if (state.booking?.type === 'timeslot') {
                        selectedDate = state.booking?.day.split('-').reverse().join('-')
                    }
                    const availableTime = days.find(day => state.booking?.type === 'timeslot' && day.date === selectedDate)
                        ?.times.flatMap(x => x)
                        ?.find(time => state.booking?.type === 'timeslot' && time.time === state.booking?.time)

                    if (state.booking && !(availableTime && state.booking?.type === 'timeslot') && state.booking?.type !== 'voucher') {
                        dispatch({type: Types.RemoveTimeslot, payload: null})
                    }
                }).catch(e => setIsDataLoading(false))

        }
    }, [state.cart.duration, gb.ready])

    useEffect(() =>{
        if (isDataLoading) {
            document.getElementById('data-loading')?.scrollIntoView({behavior:'smooth'})
        }
    }, [isDataLoading])

    const canVisitStep = (index: number) => {
        const cartItemNumbers = getTotalCount()
        if (index > 1 && cartItemNumbers > 0 && minPackages > 1 && cartItemNumbers< minPackages && state.booking && (state.booking.type == 'timeslot')) {
            setCartRuleNotMetModalVisible(true);
            return false
        }
        return index <= 0
            || (index === 1 && cartItemNumbers >= 1)
            || index === 2 && state.booking && (state.booking.type == 'timeslot' || state.booking.type === 'voucher' && state.booking.owner)
    }
    const updateActiveIndex = (index: number, updateInParent = true) => {
        if (canVisitStep(index)) {
            setTimeout(() => {
            if (scrollableRef.current) {
                //@ts-ignore
                scrollableRef.current.scrollTo(0, 0)
            }}, 100)
            if (index === 1 && !state.calendarActive) {
                index = 2
            }
            if (index === -1) {
                document.title = t("Wybór typu zamówienia");
                ReactGA.send({hitType:"pageview", page: '/welcome', title: t("Wybór typu zamówienia")})
            }
            if (index === 0) {
                posthog.capture('merch products list')
                document.title = t("Dostępne pakiety");
                ReactGA.send({hitType:"pageview", page: '/products', title: t("Dostępne pakiety")})

            } else if (index === 1) {
                document.title = t("Wybór terminu");
                posthog.capture('merch order_type select')
                ReactGA.send({hitType:"pageview", page: '/calendar', title:  t("Wybór terminu")})

            } else if (index === 2) {
                document.title = t("Podsumowanie");
                ReactGA.send({hitType:"pageview", page: '/summary', title:  t("Podsumowanie")})
                posthog.capture('merch begin_checkout')
            }
            if (updateInParent) {
                window.parent.postMessage({type: 'index_changed', prevStep: activeIndex, newStep: index}, '*')
            }
          // setHideCover(false)
           // setIsSwitchingStep(true)
           setActiveIndex(index)
           posthog.capture('$pageview')
        
        }
    }
  

    const handleChangedType = (selectedOrderType: string, index: number = 0, pTags: Array<{key:string,label:string}>=[{label: "Strzelanie", key: 'packages'}]) => {
        var tagsWereSelected = false
        if (selectedOrderType === 'booking' || selectedOrderType === 'voucher') {
            dispatch({type: Types.SelectOrderType, payload: selectedOrderType})
        }
        updateActiveIndex(index)
        if (selectedOrderType == 'voucher') {
            setSelectedTags(pTags)
            tagsWereSelected = true
            dispatch({type: Types.UpdateTimeslot, payload: {type: 'voucher', duration: state.cart.duration, owner: ''}})
        } else {
            const tag = availableTags.find(x => x.key === selectedOrderType)
            if ( tag) {
                setSelectedTags([tag])
                tagsWereSelected = true
            } 
            if (sku) {
                const product = allProducts.find( x=> x.sku === sku)

                if (product && (product.tags?.length ?? 0 )> 0) {

                    product.tags?.forEach(tag => setSelectedTags([tag]))
                    tagsWereSelected = true
                }
            }
            if (!tagsWereSelected) {
                setSelectedTags(pTags)
            }
        }
    }
    const openVoucherInput = () => {
        setActiveIndex(-1)
    }
    const dismissWindows = () => {
        try {
            localStorage.removeItem('cart')
        } catch (e) {}
        posthog.capture('exit')
        // eslint-disable-next-line
        parent.postMessage({type: "bookitone-dismiss"}, '*')
    }
    const onCartOpened = (opened: boolean) => {
        document.body.style.overflow = opened ? "hidden" : "auto";
    };

    const back = () => {
        // if (activeIndex !== 0) {
            updateActiveIndex(activeIndex - 1, true)
       // }
    }

    useEffect(() => {
        const discountsNumber = state.cart.discounts.filter(x => x.type === 'BUNDLE_VOUCHER').length
        if (discountsNumber > vouchersCounter) {
            setIsVoucherDialogOpened(true);
            setVoucherMsg(t('Możesz teraz <b>dokupić dodatkowe pakiety </b> <br/>lub od razu <b>wybrać termin realizacji</b>.'))
        }
        setVouchersCounter(discountsNumber)

    }, [state.cart.discounts])

    const toggleTag = (x: Tag) => {
        if (selectedTags.find(st => st.key === x.key)) {
            setSelectedTags([])
        } else {
            setSelectedTags([x])
        }
    }


    return (
        <React.Fragment>
            <CartRuleNotModelModal 
            dialogVisible={cartRuleNotMetModalVisible} 
            setDialogVisible={() => {setCartRuleNotMetModalVisible(false); updateActiveIndex(0, true);}}
            currentPackagesCount={getTotalCount()}
            minPackages={minPackages}
            />
            <Dialog dismissableMask={false} header={t("Niepoprawny kod QR")} visible={isVoucherErrorDialogOpened}
                    className="min-h-100 bg-white p-4 relative" onHide={() => setIsVoucherErrorDialogOpened(false)}
                    closable={false}>
                <p className="p-6 text-left text-justify">{t('Zeskanowny voucher został już wykorzystany')} <br/>{t('lub upłynął jego termin ważności.')}</p>
                <div className={"mt-auto absolute bottom-0 left-0 w-full"}>
                    <button className="mx-auto mb-8 bg-white text-secondary border-2 border-secondary rounded
                     w-72 hover:bg-secondary hover:text-white p-2  flex justify-center text font-bold mb-2 max-w-full mt-auto"
                            onClick={() => {
                                setIsVoucherErrorDialogOpened(false);
                            }}>{t('Zamknij')}
                    </button>
                </div>
            </Dialog>
            <Dialog dismissableMask={false} header={t("Dodano kupon do zamówienia")} visible={isVoucherDialogOpened}
                    className="min-h-100 bg-white p-4 relative" onHide={() => setIsVoucherDialogOpened(false)}
                    closable={false}>
                {isVoucherDialogOpened}
                <p className="p-6 text-left text-justify" dangerouslySetInnerHTML={{__html: voucherMsg}}/>
                <div className={"mt-auto absolute bottom-0 left-0 w-full"}>
                    <button className="mx-auto mb-4 bg-secondary text-white border-2 border-secondary rounded
                     w-72 hover:bg-white hover:text-secondary p-2  flex justify-center text font-bold mb-2 max-w-full mt-auto"
                            onClick={() => {
                                setIsVoucherDialogOpened(false);
                                handleChangedType('booking', 0)
                            }}>{t('Zobacz inne dostępne pakiety')}
                    </button>
                    <div className={"text-center mb-4"}>{t('lub')}</div>
                    <button className="mx-auto mb-8 bg-white text-secondary border-2 border-secondary rounded
                     w-72 hover:bg-white hover:text-secondary p-2  flex justify-center text font-bold mb-2 max-w-full mt-auto"
                            onClick={() => {
                                setIsVoucherDialogOpened(false);
                                handleChangedType('booking', 1);
                            }}>{t('Wybierz termin realizacji')}
                    </button>
                </div>
            </Dialog>

            <Dialog dismissableMask={false} header={""} visible={isVoucherInputDialogOpened}
                    className="min-h-100 bg-white relative w-1/2 md:w-1/3" onHide={() => setIsVoucherInputDialogOpened(false)}
                    closable={true}>
                <div className={"m-6 text-center"}>
               <VoucherSelector title={t("Voucher prezentowy")} description={t("Podaj kod otrzymanego vouchera i wybierz termin realizacji")} onHideVoucherSelector={() => {setIsVoucherInputDialogOpened(false)}} isIconHidden={true} voucherValidity={tenant?.voucherExpirationDays ?? 90} voucherCodeInputVisible={true}/>
                </div>
            </Dialog>
            <Dialog dismissableMask={false} header={t("Dodano kupon do zamówienia")} visible={showDurationUpdatedPopup}
                    className="min-h-72 bg-white p-4 relative" onHide={() => setShowDurationUpdatedPopup(false)}>
                <p className="p-6 text-left text-justify">
                    {t('Długość pakietu została zmieniona')}. <br/>{t('Proszę wybrać ponownie termin realizacji zamówienia')}.
                </p>
                <div className={"mt-auto absolute bottom-0 left-0 w-full"}>
                    <Button className="mx-auto mb-8 bg-secondary text-primary p-button-raised rounded
                     w-72 hover:bg-secondary-darker flex justify-center text font-bold mb-2 max-w-full border-none mt-auto"
                            label={t('OK')} onClick={() => setShowDurationUpdatedPopup(false)}/>
                </div>
            </Dialog>
            <div
                className={"max-w-1530px bg-white mx-auto rounded my-2 mx-4 md:my-4 relative overflow-y-hidden main-container"}>
                    <LanguageSelector supportedLanguages={tenant?.supportedLanguages ?? []}/>
                {dismissAndCartVisible && closeButtonVisible &&
                <React.Fragment>
                    <ConfirmPopup
                        target={document.getElementById('dismissButton') ?? undefined}
                        visible={dismissVisible}
                        onHide={() => setDismissVisible(false)}
                        acceptLabel={t("Opuść stronę")}
                        rejectLabel={t("Pozostań na stronie")}
                        message={t("Czy na pewno chcesz zamknąć?")}
                        icon="pi pi-exclamation-triangle"
                        accept={dismissWindows}
                        reject={() => setDismissVisible(false)}

                    />
                    <div
                        id={"dismissButton"}
                        className={"absolute top-2 right-2 md:top-2 lg:top-4 lg:right-6 md:right-4 p-2 hover:bg-grey rounded cursor-pointer z-20"}
                        onClick={() => setDismissVisible(true)}
                    >
                        <MdClose size={25}/>
                    </div>
                </React.Fragment>}
                {activeIndex < 0 &&
                <div className={"w-full h-full"}>
                    <OrderTypeSelector
                        isLoading={isProductsLoding}
                        products={allProducts}
                        onOrderTypeSelection={handleChangedType}
                        voucherValidity={tenant?.voucherExpirationDays ?? 90}
                    />

                </div>
                }
                 {activeIndex >= 0 && <>

                    {/* {!tenantInfo?.useProductionSettings && <div className={"text-red px-8 bg-white"}>
                        {t('Niniejszy sklep nie został jeszcze uruchomiony. Złożenie i opłacenie zamówienia możliwe będzie w ciągu kilku dni.')}
                    </div>} */}
                    <PurchaseNavigation
                        activeIndex={activeIndex}
                        onSelect={(e) => updateActiveIndex(e.index)}
                        model={menuItems}
                    />
                    {activeIndex > 0 && <BackButton onBackClicked={back} classes={"top-2"}/>}

                    
                    <div 
                        className={classNames({
                            "pb-4 max-w-screen-2xl m-auto bg-white w-full  relative xl:pl-20 lg:pl-12 lg:pr-0 md:px-6 scroll-smooth flex flex-col ": true
                        })} 
                        style={{ flexGrow: 1, overflowY: 'auto', verticalAlign: 'top'}}
                        ref={scrollableRef}
                    >
                        {isDataLoading && 
                        <div className={classNames({
                            "w-full h-full transition duration-200 ease-linear bg-white absolute": isDataLoading,
                            "opacity-100 z-50i ": isDataLoading,
                            "opacity-0": !(isDataLoading),
                            '-z-10': !isDataLoading
                        })}><LoadingScreen message={loadingScreenMessage} link={loadingScreenLink}/></div>
                    }
                        <div style={{flexGrow: 1}}>
                        <div className={"w-full lg:w-3/4  min-h-vh55 inline-block"} >
                            <div className={"lg:mr-4 h-full"}>

                                {activeIndex === 0 &&
                                    <React.Fragment>
                                        <div className={"px-2 md:px-0 text-sm md:py-2 py-2 pb-2 overflow-x-auto whitespace-nowrap"}>
                                            <div

                                                className={
                                                    classNames({
                                                        "transition-all md:text-sm mb-2 inline-block cursor-pointer rounded-full px-4 py-1 mr-1 ": true,
                                                        "bg-secondary text-white": selectedTags.length === 0,
                                                        "bg-white text-secondary hover:bg-zinc-100 ": selectedTags.length > 0,
                                                    })}
                                                    ref={selectedTags.length === 0 ? selectedOption : undefined}
                                                onClick={() => setSelectedTags([])}

                                            >{t('Wszystkie produkty')}
                                                    </div>
                                            {tags.map(x => (
                                                <div
                                                    key={x.key}
                                                    className={
                                                        classNames({
                                                            "transition-all	text-xs  md:text-sm  mb-2 inline-block cursor-pointer rounded-full px-4 py-1 mx-1 ": true,
                                                            "bg-secondary text-white hover:bg-secondary": selectedTags.length > 0 && selectedTags.find( st => st.key === x.key),
                                                            "bg-white hover:bg-zinc-100 ": selectedTags.length === 0 || !selectedTags.find( st => st.key === x.key),
                                                            "text-secondary": selectedTags.length === 0 || !selectedTags.find(st => st.key === x.key)
                                                        })}
                                                        ref={selectedTags.length > 0 && selectedTags.find( st => st.key === x.key) ? selectedOption : undefined}
                                                    onClick={() => {toggleTag(x)}}
                                                >{t(x.label)}
                                                    </div>
                                            ))}
                                        </div>
                                        <Packages
                                            packages={products.filter(x => x.type !== "SIMPLE").sort((a,b) => {return(a.position ?? 0) > (b.position ?? 0 )? 1 : -1})}
                                            items={products}
                                            availableTags={tags}
                                            setDismissButtonVisible={setdismissAndCartVisible}
                                            onTagSelected={(tag) => setSelectedTags([tag])}
                                            resetSelectedTags={()=>setSelectedTags([])}
                                            isDataLoaded={isProductsLoding}
                                            preSelectedSku={skuToOpen}
                                            resetPreSelectedSku={() => setSkuToOpen("")}
                                        />
                                    </React.Fragment>
                                }
                                {activeIndex === 1 &&
                                <>
                                <div className="px-2 md:px-0 text-2xl md:text-4xl font-bold">{t("Wybierz datę i godzinę")}</div>
                                <CalendarSelector
                                    calendarDays={calendarDays}
                                    selectedTimeSlot={state.booking}
                                    voucherValidity={tenant?.voucherExpirationDays ?? 90}
                                />
                                </>}
                                {activeIndex === 2 &&
                                <>
                                <div className="px-2 md:px-0 text-2xl md:text-4xl font-bold pb-2 lg:mb-4">{t("Podaj szczegóły zamówienia")}</div>
                                <Confirmation
                                    onUpdateStepIndex={setActiveIndex}
                                    selectedTimeslot={state.booking}
                                    setIsDataLoading={setIsDataLoading}
                                    openVoucherInput={openVoucherInput}
                                    onSetLoadingScreenMessage={setLoadingScreenMessage}
                                    onSetLoadingScreenLink={setLoadingScreenLink}
                                    useProductionSettings={tenant?.useProductionSettings ?? false}
                                    isRangePurchase={false}
                                />
                                </>
                                }
                            </div>
                        </div>
                        <div style={{verticalAlign: 'top'}} className={classNames({
                            "w-full lg:w-1/4  mt-1 lg:sticky top-0 inline-block align-top": true,
                            
                            "hidden": !dismissAndCartVisible,
                            "inline-block": dismissAndCartVisible
                        })}>
                            <Cart
                                timeslot={state.booking}
                                onGotoNextStep={() => updateActiveIndex(activeIndex + 1)}
                                activeIndex={activeIndex}
                                onCartOpen={onCartOpened}
                                onUpdateStepIndex={setActiveIndex}
                            />
                        </div>
                        <Footer/>
                        </div>
                    </div>
                </>}

                </div>

        </React.Fragment>
    );
};

export default PurchasePage;
