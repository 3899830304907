import { createContext, useContext, useEffect, useState } from "react";
import { TenantInfo } from "../models/Details.model";
import { useFeatureValue } from "@growthbook/growthbook-react";
import { usePostHog } from "posthog-js/react";
import { client, getTenantIdFromHostname } from "../utils/RealClient.service";

interface Props {
    children: React.ReactNode;
}
  
const defaultTenantId = '00000000-0000-0000-0000-000000000000';
const defaultTenantInfo ={
    bundleCommonText: "",
    reservationTerms: "",
    voucherExpirationDays: 90,
    googleAnalyticsId: "",
    themeColor: "",
    useProductionSettings: true,
    supportedLanguages:[],
    defaultLanguage:'PL',
    defaultCurrency: 'PLN',
    paymentMethods: ['ONLINE'],
    shopTabs: []};

const TenantContext = createContext<{
    tenantId: string,
    tenant: TenantInfo,
    setTenant: (tenant: TenantInfo) => void,
    setTenantId: () => string,
    isTenantRequiredShootingLeaderPermission: () => boolean,
}>({
    tenantId: defaultTenantId,
    //@ts-ignore
    tenant: defaultTenantInfo,
    setTenant: (tenant: TenantInfo) =>{},
    setTenantId: () => {return defaultTenantId},
    isTenantRequiredShootingLeaderPermission: () => false
});

const TenantProvider: React.FC<Props> = ({ children }) => {
    const isInstructorLicenseRequired = useFeatureValue('shop-ranges-requires-instructor-license', true);
    const posthog = usePostHog()
    const [tenantId, setTenantId] = useState<string>(defaultTenantId);

    useEffect(() => {
        client.defaults.headers.common['X-Tenant-Id'] = tenantId
    }, [tenantId])

    const [tenantInfo, setTenantInfo] = useState<TenantInfo>({
        tenantName: '',
        bundleCommonText: "",
        reservationTerms: "",
        voucherExpirationDays: 90,
        googleAnalyticsId: "",
        facebookPixelId: "",
        themeColor: "",
        useProductionSettings: true,
        supportedLanguages:[],
        defaultLanguage: 'PL',
        defaultCurrency: 'PLN',
        paymentMethods: [],
        facilitySettings: {
            address:"",
            backgroundPhotoUrl: "",
            city: "",
            email: "",
            facilityName: "",
            phones: [],
            regulationsUrl: "",
            socialMedia: {
                facebook: "",
                instagram: "",
                youtube:  "",
                linkedin: "",
                twitter:  "",
                tiktok:  "",
                snapchat:  "",
            },
            zipCode:  "",
            workingHours: {
                PL: []
            },
        },
        shopTabs: []});
        const isTenantRequiredShootingLeaderPermission = () => {
            return isInstructorLicenseRequired;
        }
    const tenantIdSetup = () => {
        const urlParams = new URLSearchParams(window.location.search)
        const tenantId = urlParams.get('tenantId') ?? getTenantIdFromHostname()
        setTenantId(tenantId)
        return tenantId
    }
    return (<TenantContext.Provider
    value={{
        tenant: tenantInfo,
        setTenant: setTenantInfo,
        tenantId: tenantId,
        setTenantId: tenantIdSetup,
        isTenantRequiredShootingLeaderPermission: isTenantRequiredShootingLeaderPermission,
    }}>
    {children}
    </TenantContext.Provider>)
}
const useTenant = () => useContext(TenantContext)

export {TenantContext, TenantProvider, useTenant};